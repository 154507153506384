import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://pbs.twimg.com/profile_images/1470581497249734657/LDfCopdL_400x400.jpg"
          className="App-photo"
          alt="eve-porcello"
        />
        <img src={logo} className="App-photo" alt="logo" />
        <h1>Eve Porcello</h1>
        <a
          className="App-link"
          href="https://moonhighway.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Moon Highway
        </a>
        <a
          href="https://twitter.com/eveporcello"
          className="App-link"
        >
          Twitter
        </a>
        <a
          href="https://linkedin.com/in/eveporcello"
          className="App-link"
        >
          LinkedIn
        </a>
      </header>
    </div>
  );
}

export default App;
